import React, { useState, useEffect } from 'react';

const Title = (params) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 300);
    }, []);

    return (
        <section className="front">
            <div
                className={`padded wrapper full-height ${loaded ? 'visible' : ''}`}
                id="initialClaim"
            >
                <div className="float">
                    {params.pre && <p className="super goesDown"> {params.pre} </p>}
                    {params.children}
                    {params.post && <p className="super goesUp">{params.post}</p>}
                </div>
            </div>
        </section>
    );
};

export default Title;
