import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title';

const Message = () => (
    <Layout>
        <Title pre="We received that message" post="and will get in touch soon">
            <h1 id="initialClaim1" className="claim">
                Thank you!
            </h1>
        </Title>
    </Layout>
);

export default Message;
